<template>
  <v-card
    class="overflow-hidden"
    :title="asset ? $t('assets.details.editDialog.title') : $t('assets.create')"
    :subtitle="asset ? `[${asset.key}] ${asset.description}` : undefined"
  >
    <v-form v-model="valid" class="overflow-hidden d-flex flex-column" @submit.prevent="() => onOKClick()">
      <div class="d-flex flex-wrap overflow-auto flex-grow-1">
        <div style="flex: 1; flex-basis: 360px">
          <v-card-text class="form-grid pb-0">
            <v-list-item :title="$t('general')" :prepend-icon="$t('assets.icon')" class="form__field pt-0" />

            <v-autocomplete
              v-if="currentOrganizations.length > 1"
              v-model="formValues.organization"
              class="form__field"
              :label="$t('assets.field.organization') + ' *'"
              :items="currentOrganizations"
              item-title="description"
              item-value="_id"
              :rules="[(val) => !!val || $t('validation.required')]"
            />

            <v-text-field
              v-model="formValues.description"
              class="form__field"
              :label="$t('assets.field.description') + ' *'"
              :rules="[(val) => !!val || $t('validation.required')]"
            />

            <v-text-field
              v-model="formValues.key"
              class="form__field"
              :label="$t('assets.field.key') + ' *'"
              :rules="[(val) => !!val || $t('validation.required')]"
              :error-messages="assetCodeAlreadyUsed ? [$t('assets.codeAlreadyInUse')] : []"
              :error="assetCodeAlreadyUsed"
              @update:model-value="debouncedAssetCodeAlreadyUsed"
            />

            <v-textarea v-model="formValues.remark" class="form__field" :label="$t('assets.field.remark')" />

            <v-text-field
              v-model.number="formValues.year"
              class="form__field"
              type="number"
              :label="$t('assets.field.year')"
              :rules="[
                (val) => val === undefined || val >= 0 || $t('validation.min', [0]),
                (val) => val === undefined || val <= 9999 || $t('validation.max', [9999]),
              ]"
            />

            <v-autocomplete
              v-model="formValues.category"
              class="form__field"
              :label="$t('assets.field.category') + ' *'"
              :items="categories"
              item-title="description"
              item-value="_id"
              :rules="[(val) => !!val || $t('validation.required')]"
            >
              <template #selection="{ item }">
                <category-badge style="height: 1.5rem" disable-click :category="typeof item.raw === 'object' ? item.raw : undefined" />
              </template>

              <template #item="{ item, props: itemProps }">
                <v-list-item v-bind="itemProps">
                  <template #title>
                    <category-badge density="comfortable" disable-click :category="item.raw" />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>

            <!-- Only allow selecting/changing template on create or when superadmin -->
            <v-autocomplete
              v-if="!asset || isSuperAdmin"
              v-model="formValues.template"
              class="form__field"
              :loading="templatesStatus === 'pending'"
              :label="$t('assets.field.template') + ' *'"
              :items="templates"
              item-title="description"
              item-value="_id"
              :rules="[(val) => !!val || $t('validation.required')]"
            >
              <template v-if="templates.some((template) => !!template.organization)" #item="{ item, props: itemProps }">
                <v-list-item v-bind="itemProps">
                  <template #default>
                    <v-chip v-if="item.raw.organization" label density="compact" prepend-icon="$organization">
                      {{ item.raw.organization.description }}
                    </v-chip>

                    <v-chip v-else label density="compact" prepend-icon="$assetTemplate">
                      {{ $t("default") }}
                    </v-chip>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>

            <!-- Disable template field for normal users when editing -->
            <v-text-field
              v-else
              v-model="formValues.template"
              class="form__field"
              :label="$t('assets.field.template') + ' *'"
              readonly
              disabled
            />

            <v-autocomplete
              v-model="formValues.tags"
              multiple
              class="form__field"
              :loading="pendingAssetTags"
              :label="$t('assets.field.tags')"
              :items="assetTags"
              item-title="description"
              item-value="_id"
            >
              <template #selection="{ item, index }">
                <asset-tag-badge v-if="index < 2" :asset-tag="item.raw" />
                <span v-else-if="index === 2" class="text-caption align-self-center">(+{{ (formValues.tags?.length ?? 0) - 2 }})</span>
              </template>

              <template #item="{ props: itemProps, item }">
                <v-list-item v-bind="itemProps">
                  <template #title>
                    <asset-tag-badge :asset-tag="item.raw" />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>

            <v-select
              v-model="formValues.riskProfile"
              class="form__field"
              :label="$t('assets.field.riskProfile')"
              :items="[RiskProfile.UNKNOWN, RiskProfile.LOW, RiskProfile.MEDIUM, RiskProfile.HIGH, RiskProfile.VERY_HIGH]"
            >
              <template #selection="{ item }">
                <risk-profile-badge :risk-profile="item.raw" />
              </template>

              <template #item="{ item, props: itemProps }">
                <v-list-item v-bind="itemProps">
                  <template #title>
                    <risk-profile-badge :risk-profile="item.raw" />
                  </template>
                </v-list-item>
              </template>
            </v-select>
          </v-card-text>

          <v-card-text class="form-grid pb-0">
            <asset-external-keys
              v-if="asset && assetOrganization && hasScope(assetOrganization, AuthScope.CAN_VIEW_OBJECTS_PAIRING_CODES_OVERVIEW)"
              :asset-id="asset._id"
              :organization-id="asset.organization"
            />
          </v-card-text>

          <v-card-text class="form-grid pb-0">
            <asset-sharing
              v-if="asset"
              :asset-id="asset._id"
              :asset-organization-id="asset.organization"
              :readonly="!hasScope(asset.organization, AuthScope.ADMIN)"
            />
          </v-card-text>
        </div>

        <div style="flex: 1; flex-basis: 360px">
          <v-card-text class="form-grid pb-0">
            <v-list-item
              :title="$t('coordinates')"
              :subtitle="$t('googleMaps.dragMarker')"
              prepend-icon="explore"
              class="form__field pt-0"
            />

            <v-card class="form__field mb-2" variant="flat">
              <google-map
                class="form__field"
                style="height: 300px"
                :center="currentCoordinates"
                :zoom="15"
                :assets="[]"
                :show-main-asset-relations="false"
              >
                <template #custom-markers>
                  <g-map-marker
                    v-model="formValues.location!.gps"
                    :position="currentCoordinates"
                    :draggable="true"
                    @dragend="onGpsDragEnd"
                  />
                </template>
              </google-map>
            </v-card>

            <div class="d-flex form__field" style="gap: 8px">
              <template v-if="showGPSCoordinates">
                <v-text-field
                  :model-value="gpsYStringValue"
                  pattern="[-+]?[0-9]*[.]?[0-9]+"
                  step="any"
                  :label="$t('assets.field.gpsY') + ' *'"
                  :rules="[
                    (val) => !!val || $t('validation.required'),
                    (val) => Number(val) >= GPS_Y_MIN || $t('validation.min', [GPS_Y_MIN]),
                    (val) => Number(val) <= GPS_Y_MAX || $t('validation.max', [GPS_Y_MAX]),
                  ]"
                  :hint="formValues.location?.rd && `${formValues.location.rd.coordinates[0].toFixed(2)}m (${$t('assets.field.rdY')})`"
                  persistent-hint
                  :suffix="formValues.location!.gps!.coordinates[0] > 0 ? 'N°' : 'S°'"
                  @update:model-value="(val) => debounceGpsY(val)"
                />

                <v-text-field
                  :model-value="gpsXStringValue"
                  pattern="[-+]?[0-9]*[.]?[0-9]+"
                  step="any"
                  :label="$t('assets.field.gpsX') + ' *'"
                  :rules="[
                    (val) => !!val || $t('validation.required'),
                    (val) => Number(val) >= GPS_X_MIN || $t('validation.min', [GPS_X_MIN]),
                    (val) => Number(val) <= GPS_X_MAX || $t('validation.max', [GPS_X_MAX]),
                  ]"
                  :hint="formValues.location?.rd && `${formValues.location.rd.coordinates[1].toFixed(2)}m (${$t('assets.field.rdX')})`"
                  persistent-hint
                  :suffix="formValues.location!.gps!.coordinates[1] > 0 ? 'E°' : 'W°'"
                  @update:model-value="(val) => debounceGpsX(val)"
                />
              </template>
              <template v-else>
                <v-text-field
                  :model-value="rdXStringValue"
                  pattern="[-+]?[0-9]*[.]?[0-9]+"
                  step="any"
                  :label="$t('assets.field.rdX') + ' *'"
                  :hint="formValues.location?.gps && `${formValues.location.gps.coordinates[0].toFixed(7)} (${$t('assets.field.gpsX')})`"
                  persistent-hint
                  :rules="[
                    (val) => !!val || $t('validation.required'),
                    (val) => Number(val) >= RD_X_MIN || $t('validation.min', [RD_X_MIN]),
                    (val) => Number(val) <= RD_X_MAX || $t('validation.max', [RD_X_MAX]),
                  ]"
                  suffix="M"
                  @update:model-value="(val) => debounceRdX(val)"
                />

                <v-text-field
                  :model-value="rdYStringValue"
                  pattern="[-+]?[0-9]*[.]?[0-9]+"
                  :label="$t('assets.field.rdY') + ' *'"
                  :hint="formValues.location?.gps && `${formValues.location.gps.coordinates[1].toFixed(7)} (${$t('assets.field.gpsY')})`"
                  persistent-hint
                  :rules="[
                    (val) => !!val || $t('validation.required'),
                    (val) => Number(val) >= RD_Y_MIN || $t('validation.min', [RD_Y_MIN]),
                    (val) => Number(val) <= RD_Y_MAX || $t('validation.max', [RD_Y_MAX]),
                  ]"
                  suffix="M"
                  @update:model-value="(val) => debounceRdY(val)"
                />
              </template>

              <v-tooltip>
                <template #activator="{ props: tooltipProps }">
                  <v-btn v-bind="tooltipProps" icon="shuffle" @click="showGPSCoordinates = !showGPSCoordinates" />
                </template>

                <template #default>{{ $t("googleMaps.toggleRDCoordinates") }}</template>
              </v-tooltip>
            </div>

            <v-text-field
              v-if="showGPSCoordinates"
              v-model.number="formValues.location!.gps!.coordinates[2]"
              pattern="[-+]?[0-9]*[.]?[0-9]+"
              step="any"
              class="form__field"
              :label="$t('assets.field.gpsZ')"
              suffix="M"
            />

            <v-text-field
              v-else
              v-model.number="formValues.location!.rd!.coordinates[2]"
              pattern="[-+]?[0-9]*[.]?[0-9]+"
              step="any"
              class="form__field"
              :label="$t('assets.field.rdZ')"
              suffix="M"
            />
          </v-card-text>

          <v-card-text class="form-grid">
            <floating-card-header :title="$t('address')" icon="location_on" class="form__field pt-0" />

            <v-text-field
              id="autocomplete"
              class="form__field"
              prepend-inner-icon="search"
              :model-value="addressSearch"
              @update:focused.once="initAutoComplete"
            />

            <v-combobox
              v-model="formValues.location!.street"
              class="form__field"
              :label="$t('assets.field.street')"
              clearable
              :items="streets.map((street) => street.description)"
            />

            <div class="form-grid form__field">
              <v-text-field v-model="formValues.location!.houseNumber" :label="$t('assets.field.houseNumber')" />

              <v-text-field v-model="formValues.location!.zipCode" :label="$t('assets.field.zipCode')" />
            </div>

            <v-combobox
              v-model="formValues.location!.city"
              class="form__field"
              :label="$t('assets.field.city')"
              clearable
              :items="cities.map((city) => city.description)"
            />

            <v-combobox
              v-model="formValues.location!.district"
              class="form__field"
              :label="$t('assets.field.district')"
              clearable
              :items="districts.map((district) => district.description)"
            />

            <v-combobox
              v-model="formValues.location!.municipality"
              class="form__field"
              :label="$t('assets.field.municipality')"
              clearable
              :items="municipalities.map((municipality) => municipality.description)"
            />

            <v-combobox
              v-model="formValues.location!.area"
              class="form__field"
              :label="$t('assets.field.area')"
              clearable
              :items="areas.map((area) => area.description)"
            />
          </v-card-text>
        </div>
      </div>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
        <v-btn :loading="pending" color="primary" type="submit">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
import cloneDeep from "lodash-es/cloneDeep"
import clone from "lodash-es/clone"
import debounce from "lodash-es/debounce"
import { GPS_X_MAX, GPS_X_MIN, GPS_Y_MAX, GPS_Y_MIN, RD_X_MAX, RD_X_MIN, RD_Y_MAX, RD_Y_MIN } from "~~/utils/globals"
import { RiskProfile } from "~~/stores/riskSettings.store"
import { AuthScope } from "~~/stores/auth.store"
import sortBy from "lodash-es/sortBy"
import type { CreateAssetPayload } from "~~/stores/asset.store"
import omit from "lodash-es/omit"
import isEqual from "lodash-es/isEqual"

const emit = defineEmits<{
  (e: "ok", asset: Asset): void
  (e: "cancel"): void
}>()
const props = defineProps<{ asset?: Asset }>()
const { asset } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const authStore = useAuthStore()
const assetStore = useAssetStore()
const assetCategoryStore = useAssetCategoryStore()
const streetStore = useStreetStore()
const cityStore = useCityStore()
const areaStore = useAreaStore()
const districtStore = useDistrictStore()
const municipalityStore = useMunicipalityStore()
const organizationStore = useOrganizationStore()
const assetTagStore = useAssetTagStore()
const templateStore = useTemplateStore()
const geoStore = useGeoStore()

const { items: streets } = storeToRefs(streetStore)
const { items: cities } = storeToRefs(cityStore)
const { items: areas } = storeToRefs(areaStore)
const { items: districts } = storeToRefs(districtStore)
const { items: municipalities } = storeToRefs(municipalityStore)
const { isSuperAdmin } = storeToRefs(authStore)
const { currentOrganizations } = storeToRefs(organizationStore)

const assetOrganization = computed(() => asset?.value?.organization || currentOrganizations.value[0]?._id)

const defaultCoordinates = computed(() => {
  const organization = currentOrganizations.value.find((org) => org._id === assetOrganization.value)
  return organization?.location?.coordinates ? geoPointToLatLng(organization?.location) : defaultCoords
})

const { data: categories } = useLazyAsyncData("categories", () => assetCategoryStore.getCategories(assetOrganization.value), {
  default: () => [] as Array<AssetCategory>,
})

const { data: templates, status: templatesStatus } = useLazyAsyncData(
  "templates",
  async () => {
    // Only super admins may update templates, skip api call if not super admin
    if (!!asset.value && !isSuperAdmin.value) {
      return []
    }
    const templateResponse = await templateStore.getTemplates()
    return sortBy(
      templateResponse,
      (template) => !template.organization,
      (template) => template.description,
    )
  },
  { default: () => [] as Array<SmallPopulatedTemplate> },
)
const { data: assetTags, pending: pendingAssetTags } = useLazyAsyncData(
  "assetTags",
  () => assetTagStore.getAssetTags(false, assetOrganization.value),
  { default: () => [] as Array<AssetTag> },
)

const formValues = ref<Partial<CreateAssetPayload>>({
  category: clone(asset?.value?.category._id),
  template: clone(asset?.value?.template._id),
  key: clone(asset?.value?.key),
  year: clone(asset?.value?.year),
  description: clone(asset?.value?.description),
  remark: clone(asset?.value?.remark),
  riskProfile: clone(asset?.value?.riskProfile) ?? RiskProfile.UNKNOWN,
  organization: clone(assetOrganization.value),
  location: {
    ...cloneDeep(asset?.value?.location),
    gps: cloneDeep(asset?.value?.location.gps) || {
      type: "Point",
      coordinates: [defaultCoordinates.value.lng, defaultCoordinates.value.lat, 0],
    },
    rd: cloneDeep(asset?.value?.location.rd) || { type: "Point", coordinates: [0, 0, 0] },
  },
  tags: clone(asset?.value?.tags?.map((tag) => tag._id)) ?? [],
})

const pending = ref(false)

const addressSearch = ref("")
const showGPSCoordinates = ref(true)
const assetCodeAlreadyUsed = ref(false)
const valid = ref(false)

const gpsXStringValue = ref(formValues.value.location?.gps?.coordinates[0].toString())
const gpsYStringValue = ref(formValues.value.location?.gps?.coordinates[1].toString())
const rdXStringValue = ref(formValues.value.location?.rd?.coordinates[1].toString())
const rdYStringValue = ref(formValues.value.location?.rd?.coordinates[0].toString())

const { hasScope } = authStore

const debounceGpsX = debounce(async (value: string) => {
  gpsXStringValue.value = value.replace(",", ".")
  const numberValue = Number(value.replace(",", "."))
  if (isNaN(numberValue) || numberValue < GPS_X_MIN || numberValue > GPS_X_MAX) {
    return
  }

  formValues.value.location!.gps!.coordinates[0] = numberValue
  const newRDCoordinates = await geoStore.convertWGSToRD(formValues.value.location!.gps!)
  formValues.value.location!.rd = newRDCoordinates
}, DEFAULT_DEBOUNCE_TIME * 2)

const debounceGpsY = debounce(async (value: string) => {
  gpsYStringValue.value = value.replace(",", ".")
  const numberValue = Number(value.replace(",", "."))
  if (isNaN(numberValue) || numberValue < GPS_Y_MIN || numberValue > GPS_Y_MAX) {
    return
  }

  formValues.value.location!.gps!.coordinates[1] = numberValue
  const newRDCoordinates = await geoStore.convertWGSToRD(formValues.value.location!.gps!)
  formValues.value.location!.rd = newRDCoordinates
}, DEFAULT_DEBOUNCE_TIME * 2)

const debounceRdX = debounce(async (value: string) => {
  rdXStringValue.value = value.replace(",", ".")
  const numberValue = Number(value.replace(",", "."))
  if (isNaN(numberValue) || numberValue < RD_X_MIN || numberValue > RD_X_MAX) {
    return
  }

  formValues.value.location!.rd!.coordinates[1] = numberValue
  const newGPSCoordinates = await geoStore.convertRDToWGS(formValues.value.location!.rd!)
  formValues.value.location!.gps = newGPSCoordinates
}, DEFAULT_DEBOUNCE_TIME * 2)

const debounceRdY = debounce(async (value: string) => {
  rdYStringValue.value = value.replace(",", ".")
  const numberValue = Number(value.replace(",", "."))
  if (isNaN(numberValue) || numberValue < RD_Y_MIN || numberValue > RD_Y_MAX) {
    return
  }

  formValues.value.location!.rd!.coordinates[0] = numberValue
  const newGPSCoordinates = await geoStore.convertRDToWGS(formValues.value.location!.rd!)
  formValues.value.location!.gps = newGPSCoordinates
}, DEFAULT_DEBOUNCE_TIME * 2)

const debouncedAssetCodeAlreadyUsed = debounce(async () => {
  const assetListWithCode = await assetStore.getCount({ key: formValues.value.key, organization: formValues.value.organization })
  assetCodeAlreadyUsed.value = assetListWithCode > 0
}, DEFAULT_DEBOUNCE_TIME)

const currentCoordinates = computed(() => {
  const gps = formValues.value.location?.gps

  return geoPointToLatLng({
    type: "Point",
    coordinates: [
      gps && !Number.isFinite(gps?.coordinates[0]) ? 0 : gps?.coordinates[0] || defaultCoordinates.value.lng,
      gps && !Number.isFinite(gps?.coordinates[1]) ? 0 : gps?.coordinates[1] || defaultCoordinates.value.lat,
    ],
  })
})

const placeChanged = async (
  placeResult: google.maps.places.PlaceResult,
  options?: { ignoreGeometry?: boolean; noConversion?: boolean },
) => {
  addressSearch.value = placeResult.formatted_address || ""

  const addressComponents = placeResult.address_components
  const geometryLocation = placeResult.geometry?.location

  const gps: GeoPoint =
    !options?.ignoreGeometry && geometryLocation
      ? {
          type: "Point",
          coordinates: [
            parseFloat(geometryLocation.lng().toFixed(7)),
            parseFloat(geometryLocation.lat().toFixed(7)),
            formValues.value.location?.gps?.coordinates[2] || 0,
          ],
        }
      : formValues.value.location!.gps!

  if (!addressComponents) return

  const houseNumber = addressComponents.find((c) => c.types.includes("street_number"))?.long_name
  const street = addressComponents.find((c) => c.types.includes("route"))?.long_name
  const zipCode = addressComponents.find((c) => c.types.includes("postal_code"))?.long_name
  const area = addressComponents.find((c) => c.types.includes("sublocality_level_1"))?.long_name
  const city = addressComponents.find((c) => c.types.includes("locality"))?.long_name
  const municipality = addressComponents.find((c) => c.types.includes("administrative_area_level_2"))?.long_name

  formValues.value.location = {
    ...formValues.value.location,
    houseNumber,
    street,
    zipCode,
    area,
    city,
    municipality,
    gps,
    rd: options?.ignoreGeometry || options?.noConversion ? formValues.value.location?.rd : await geoStore.convertWGSToRD(gps),
  }

  gpsXStringValue.value = formValues.value.location?.gps?.coordinates[0].toString()
  gpsYStringValue.value = formValues.value.location?.gps?.coordinates[1].toString()
  rdXStringValue.value = formValues.value.location?.rd?.coordinates[1].toString()
  rdYStringValue.value = formValues.value.location?.rd?.coordinates[0].toString()
}

const onGpsDragEnd = async (e: { latLng: google.maps.LatLng }) => {
  // get location address from google maps api
  const geocoder = new google.maps.Geocoder()
  geocoder.geocode({ location: e.latLng }, async (results, status) => {
    if (status === "OK" && results?.length) {
      const gpsPoint: GeoPoint = {
        type: "Point",
        coordinates: [
          parseFloat(e.latLng.lng().toFixed(7)),
          parseFloat(e.latLng.lat().toFixed(7)),
          formValues.value.location?.gps?.coordinates[2] || 0,
        ],
      }

      // Update GPS immediately, don't wait for RD
      // This prevents the marker from jumping back to the old location
      // until the RD conversion is done
      formValues.value.location = {
        ...formValues.value.location,
        gps: gpsPoint,
      }

      await placeChanged(results[0]!, { ignoreGeometry: true })
      geoStore.convertWGSToRD(gpsPoint).then((rdPoint) => {
        formValues.value.location!.rd = rdPoint
      })

      gpsXStringValue.value = formValues.value.location?.gps?.coordinates[0].toString()
      gpsYStringValue.value = formValues.value.location?.gps?.coordinates[1].toString()
      rdXStringValue.value = formValues.value.location?.rd?.coordinates[1].toString()
      rdYStringValue.value = formValues.value.location?.rd?.coordinates[0].toString()
    }
  })
}

async function onOKClick() {
  if (!valid.value) {
    return
  }

  const parsedYear = typeof formValues.value.year === "number" ? formValues.value.year : undefined

  const newAssetData: CreateAssetPayload = {
    category: formValues.value.category!,
    description: formValues.value.description!,
    key: formValues.value.key!,
    template: formValues.value.template!,
    year: parsedYear,
    organization: formValues.value.organization!,
    remark: formValues.value.remark,
    riskProfile: formValues.value.riskProfile!,
    tags: formValues.value.tags!,
    location: {
      ...(formValues.value.location?.area ? { area: formValues.value.location.area } : {}),
      ...(formValues.value.location?.city ? { city: formValues.value.location.city } : {}),
      ...(formValues.value.location?.district ? { district: formValues.value.location.district } : {}),
      ...(formValues.value.location?.houseNumber ? { houseNumber: formValues.value.location.houseNumber } : {}),
      ...(formValues.value.location?.municipality ? { municipality: formValues.value.location.municipality } : {}),
      ...(formValues.value.location?.street ? { street: formValues.value.location.street } : {}),
      ...(formValues.value.location?.zipCode ? { zipCode: formValues.value.location.zipCode } : {}),
      ...(showGPSCoordinates.value
        ? {
            gps: formValues.value.location?.gps
              ? {
                  ...formValues.value.location.gps,
                  coordinates: formValues.value.location.gps.coordinates[2]
                    ? [
                        formValues.value.location.gps.coordinates[0],
                        formValues.value.location.gps.coordinates[1],
                        formValues.value.location.gps.coordinates[2],
                      ]
                    : [formValues.value.location.gps.coordinates[0], formValues.value.location.gps.coordinates[1]],
                }
              : undefined,
          }
        : {
            rd: formValues.value.location?.rd
              ? {
                  ...formValues.value.location.rd,
                  coordinates: formValues.value.location.rd.coordinates[2]
                    ? [
                        formValues.value.location.rd.coordinates[0],
                        formValues.value.location.rd.coordinates[1],
                        formValues.value.location.rd.coordinates[2],
                      ]
                    : [formValues.value.location.rd.coordinates[0], formValues.value.location.rd.coordinates[1]],
                }
              : undefined,
          }),
    },
  }

  if (valid.value && !assetCodeAlreadyUsed.value) {
    await useExplicitSave(mainStore.notify, $i18n, async () => {
      pending.value = true

      try {
        if (asset?.value) {
          const addressChanged = !isEqual(omit(asset.value.location, ["gps", "rd"]), omit(newAssetData.location, ["gps", "rd"]))
          const coordinatesChanged = showGPSCoordinates.value
            ? !isEqual(asset.value.location.gps, newAssetData.location.gps)
            : !isEqual(asset.value.location.rd, newAssetData.location.rd)
          const locationChanged = addressChanged || coordinatesChanged

          const updatedAsset = await assetStore.patchOne(asset.value._id, {
            category: newAssetData.category === asset.value.category._id ? undefined : newAssetData.category,
            description: newAssetData.description === asset.value.description ? undefined : newAssetData.description,
            key: newAssetData.key === asset.value.key ? undefined : newAssetData.key,
            template: newAssetData.template === asset.value.template._id ? undefined : newAssetData.template,
            year: newAssetData.year === asset.value.year ? undefined : newAssetData.year,
            location: locationChanged ? newAssetData.location : undefined,
            remark: newAssetData.remark === asset.value.remark ? undefined : newAssetData.remark,
            riskProfile: newAssetData.riskProfile === asset.value.riskProfile ? undefined : newAssetData.riskProfile,
            tags: isEqual(
              newAssetData.tags,
              asset.value.tags?.map((tag) => tag._id),
            )
              ? undefined
              : newAssetData.tags,
          })

          emit("ok", updatedAsset)
        } else {
          const newAsset = await assetStore.createOne({
            category: newAssetData.category!,
            key: newAssetData.key!,
            year: newAssetData.year,
            description: newAssetData.description!,
            remark: newAssetData.remark,
            location: newAssetData.location!,
            riskProfile: newAssetData.riskProfile!,
            template: newAssetData.template!,
            tags: newAssetData.tags!,
            organization: newAssetData.organization!,
          })

          emit("ok", newAsset)
        }
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        pending.value = false
      }
    })
  }
}

watch(currentCoordinates, (newVal, oldVal) => {
  if (JSON.stringify(oldVal) === JSON.stringify(newVal)) return

  const geocoder = new google.maps.Geocoder()
  geocoder.geocode({ location: newVal }, (results, status) => {
    if (status === "OK" && results?.length) {
      placeChanged(results[0]!, { ignoreGeometry: true, noConversion: !showGPSCoordinates.value })
    }
  })
})

const initAutoComplete = () => {
  // Google Places autocomplete
  const autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete") as HTMLInputElement, {
    fields: ["address_components", "geometry", "formatted_address"],
    componentRestrictions: { country: "nl" },
  })

  autocomplete.addListener("place_changed", () => {
    placeChanged(autocomplete.getPlace())
  })
}

onMounted(() => {
  // our dropdown
  Promise.all([streetStore.getItems(), cityStore.getItems(), areaStore.getItems(), districtStore.getItems(), municipalityStore.getItems()])
})
</script>
