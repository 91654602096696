<template>
  <v-card :title="externalKey ? $t('assets.externalKeys.edit') : $t('assets.externalKeys.add')">
    <v-form @submit.prevent="() => onOKClick()">
      <v-card-text class="d-flex flex-column" style="gap: 8px">
        <v-select
          v-model="application"
          :label="$t('assets.field.externalKeys.externalKeyApplication')"
          :disabled="!!externalKey"
          :items="applications"
          item-title="description"
          item-value="_id"
          :loading="applicationsStatus === 'pending'"
          :rules="[(val) => !!val || $t('validation.required')]"
        >
          <template #selection="{ item }">{{ item.raw.description }} ({{ item.raw.company }})</template>
          <template #item="{ item, props: itemProps }">
            <v-list-item v-bind="itemProps" :title="`${item.raw.description} (${item.raw.company})`" :subtitle="item.raw.category" />
          </template>
        </v-select>

        <v-text-field
          v-model="value"
          :label="$t('assets.field.externalKeys.value')"
          :rules="[(val) => !!val || $t('validation.required')]"
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="onCancelClick">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" type="submit">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "ok", data: Partial<ExternalKey>): void
  (e: "cancel"): void
}>()
const props = defineProps<{ assetId: string; externalKey?: ExternalKey }>()
const { assetId, externalKey } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const assetStore = useAssetStore()
const externalKeyApplicationStore = useExternalKeyApplicationStore()

const { data: applications, status: applicationsStatus } = useAsyncData(
  "application-options",
  async () => {
    return await externalKeyApplicationStore.getExternalKeyApplications({ sort: "description" })
  },
  { default: () => [] as Array<ExternalKeyApplication>, lazy: true },
)

const value = ref(props.externalKey?.value)
const application = ref(props.externalKey?.application._id)

const onOKClick = async () => {
  if (!application.value || !value.value) {
    return
  }

  const data = {
    value: value.value,
    application: application.value,
  }

  await useExplicitSave(mainStore.notify, $i18n, async () => {
    if (externalKey?.value) {
      await assetStore.updateExternalKey(assetId.value, externalKey.value._id, data.value)
    } else {
      await assetStore.addExternalKey(assetId.value, data)
    }

    emit("ok", {
      application: applications.value.find((app) => app._id === application.value),
      value: data.value,
    })
  })
}

const onCancelClick = () => emit("cancel")
</script>
